@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  position: relative;
  @include globals.flexColumnCenter;
  width: 100%;
  height: 100vh;
  img {
    @include globals.WH100_cover;
  }
  .text {
    position: absolute;
    top: 7rem;
    left: 50%;
    transform: translateX(-50%);
    .errorType {
      margin-bottom: 5rem;
      padding: 1.5rem 0;
      color: globals.$color-2;
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 2rem;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .details {
      @include globals.flexColumnCenter;
      .errorDescription {
        margin-bottom: 3rem;
        padding: 1.5rem 0;
        font-size: 2rem;
        font-weight: 300;
        text-align: center;
        color: globals.$color-2;
        background-color: rgba(0, 0, 0, 0.8);
      }
      .backHome {
        position: relative;
        padding: 0.5rem 0;
        font-size: 1.5rem;
        font-weight: 300;
        text-align: center;
        color: globals.$color-2;
        background-color: rgba(0, 0, 0, 0.8);
        &:after {
          @include globals.afterUnderline;
          background: globals.$color-2;
        }
        &:hover:after {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    .text {
      .errorType {
        margin-bottom: 5rem;
        font-size: 2.2rem;
        letter-spacing: 2rem;
      }
      .details {
        .errorDescription {
          margin-bottom: 3rem;
          font-size: 1.8rem;
        }
        .backHome {
          font-size: 1.5rem;
          color: globals.$color-2;
        }
      }
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    .text {
      .errorType {
        margin-bottom: 4rem;
        font-size: 1.8rem;
        letter-spacing: 1.8rem;
      }
      .details {
        .errorDescription {
          margin-bottom: 2rem;
          font-size: 1.6rem;
        }
        .backHome {
          font-size: 1.4rem;
          color: globals.$color-2;
        }
      }
    }
  }
}

// MOBILE DEVICES //

@media screen and (max-width: 480px) {
  .container {
    .text {
      .errorType {
        margin-bottom: 3rem;
        font-size: 1.6rem;
        letter-spacing: 1.6rem;
      }
      .details {
        .errorDescription {
          margin-bottom: 0;
          font-size: 1.4rem;
        }
        .backHome {
          margin-top: 34rem;
          font-size: 1.3rem;
          padding: 0.5rem 1rem;
          color: globals.$color-2;
        }
      }
    }
  }
}
