@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  @include globals.flexColumnCenter;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 7rem;
  h1 {
    margin: 7rem 0 9rem 0;
    @include globals.configh1;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    h1 {
      font-size: 2.2rem;
    }
  }
}

// IPADS, TABLETS

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    h1 {
      font-size: 1.8rem;
      letter-spacing: 1.8rem;
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 290px) and (max-width: 480px) {
  .container {
    h1 {
      font-size: 1.5rem;
      letter-spacing: 1.5rem;
    }
  }
}
