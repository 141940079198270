@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  video {
    @include globals.WH100_cover;
  }
  .muteIcon {
    z-index: 4;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    color: globals.$color-3;
    cursor: pointer;
  }
  .text {
    z-index: 1;
    position: absolute;
    top: 4rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60%;
    font-family: globals.$font-family-2;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    color: globals.$color-3;
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    .text {
      width: 70%;
      font-size: 1.5rem;
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    .text {
      width: 75%;
      font-size: 1.4rem;
    }
  }
}

// MOBILE DEVICES //

@media screen and (max-width: 480px) {
  .container {
    .muteIcon {
      z-index: 1;
      width: 1.5rem;
      height: 1.5rem;
    }
    .text {
      width: 90%;
      font-size: 1.2rem;
    }
  }
}
