@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  padding: 7rem 6rem 10rem 6rem;
  text-align: center;
  h2 {
    margin-bottom: 8rem;
    @include globals.configh1;
  }
  .details {
    margin: 0 auto;
    h2 {
      margin-bottom: 5rem;
      font-family: globals.$font-family-2;
      font-size: 2.5rem;
      text-transform: none;
      font-weight: bold;
      letter-spacing: normal;
    }
    .directors,
    h3 {
      font-size: 2rem;
    }
    .directors {
      font-weight: bold;
    }
    h3 {
      font-weight: 300;
    }
    .directorsEmailContainer .directorsEmail,
    .administrationEmail {
      cursor: pointer;
    }
    .directorsEmailContainer,
    .administrationPhone{
      margin-bottom: 2rem;
    }
    .administration {
      font-size: 2rem;
      font-weight: bold;
    }
    .directorsEmailContainer .directorsEmail,
    .administrationEmail,
    .administrationPhone{
      font-size: 1.8rem;
      font-weight: 300;
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    h2,
    .details h2 {
      font-size: 2.2rem;
    }
    .details {
      .directors,
      h3,
      .administration {
        font-size: 1.8rem;
      }
      .directorsEmailContainer .directorsEmail,
      .administrationEmail,
      .administrationPhone {
        font-size: 1.6rem;
      }
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    h2,
    .details h2 {
      font-size: 1.8rem;
    }
    h2 {
      letter-spacing: 1.8rem;
    }
    .details {
      .directors,
      h3,
      .administration {
        font-size: 1.6rem;
      }
      .directorsEmailContainer .directorsEmail,
      .administrationEmail,
      .administrationPhone {
        font-size: 1.4rem;
      }
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 280px) and (max-width: 480px) {
  .container {
    h2,
    .details h2 {
      font-size: 1.5rem;
    }
    h2 {
      letter-spacing: 1.5rem;
    }
    .details {
      .directors,
      h3 {
        font-size: 1.4rem;
      }
      .directorsEmailContainer .directorsEmail,
      .administrationEmail,
      .administrationPhone,
      .administration {
        font-size: 1.4rem;
      }
    }
  }
}
