// VARIABLES

$color-1: black;
$color-2: white;
$color-3: #666666;

$font-family-1: sans-serif;
$font-family-2: "Archivo", sans-serif;

// MIXINS

@mixin configh1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2rem;
  text-align: center;
}

@mixin configh2 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1.5rem;
  text-align: center;
}

@mixin positionAbsolute {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin WH100_cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flexRowCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin afterUnderline {
  content: "";
  position: absolute;
  bottom: 0rem;
  left: 50%;
  display: block;
  width: 0;
  height: 1px;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

@mixin arrowConfig {
  font-size: 1.4rem;
  font-weight: 100;
  color: $color-2;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $color-3;
    transform: scale(1.3);
  }
}

// STYLES GLOBAUX APPLIQUÉS À L'ENSEMBLE DU SITE

:root {
  // 1rem = 10px //
  font-size: 62.5%;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  font-family: $font-family-1;
}

a {
  text-decoration: none;
  color: $color-1;
  cursor: pointer;
}

a:visited {
  color: $color-1;
}

li {
  list-style-type: none;
}
