@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  padding: 7rem 6rem;
  height: fit-content;
  text-align: center;
  h1 {
    margin-bottom: 8rem;
    @include globals.configh1;
  }
  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
    .card {
      position: relative;
      width: 160px;
      height: 200px;
      border-left: 1px solid globals.$color-1;
      @include globals.flexColumnCenter;
      margin: 4rem 3rem;
      padding-left: 2rem;
      .logo {
        width: 14rem;
        height: 14rem;
        padding-left: 2rem;
        object-fit: contain;
      }
      .partnerName {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 160px;
        height: 160px;
        margin: 2rem 0 0 2rem;
        background-color: globals.$color-1;
        transition: 0.5s;
        p {
          width: 100%;
          margin-bottom: 3rem;
          padding: 1rem;
          font-family: globals.$font-family-1;
          font-size: 1.4rem;
          color: globals.$color-2;
        }
        &:hover {
          opacity: 0.8;
          color: globals.$color-2;
        }
      }
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    h1 {
      font-size: 2.2rem;
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    h1 {
      font-size: 1.8rem;
      letter-spacing: 1.8rem;
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 290px) and (max-width: 480px) {
  .container {
    padding: 7rem 2rem;
    h1 {
      font-size: 1.5rem;
      letter-spacing: 1.5rem;
      margin-bottom: 3rem;
    }
  }
}
