@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  @include globals.flexColumnCenter;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 3rem;
  h1 {
    margin: 7rem 0 5rem 0;
    @include globals.configh1;
  }
  .docRequest {
      font-family: globals.$font-family-2;
      font-style: italic;
      font-size: 1.2rem;
      margin-bottom: 9rem;
      text-align: center;
  }
  .subtitle {
    @include globals.configh2;
    margin-bottom: 10rem;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 5rem;
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    h1 {
      margin: 5rem 0;
      font-size: 2.2rem;
    }
    .subtitle {
      font-size: 1.8rem;
    }
  }
}

// IPADS, TABLETS // & // MOBILE DEVICES //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    h1 {
      margin: 5rem 0;
      font-size: 1.8rem;
    }
    .subtitle {
      font-size: 1.6rem;
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 290px) and (max-width: 480px) {
  .container {
    h1 {
      margin: 5rem 0 8rem 0;
      font-size: 1.5rem;
      letter-spacing: 1.3rem;
    }
    .subtitle {
      font-size: 1.2rem;
      letter-spacing: 1.1rem;
    }
  }
}
