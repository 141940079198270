@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  position: absolute;
  @include globals.flexRowCenter;
  width: 100%;
  height: 17%;
  background-color: globals.$color-1;
  .legals {
    text-align: center;
    .legalsDetails {
      p {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        color: globals.$color-2;
        &:nth-child(2) {
          cursor: pointer;
        }
      }
    }
  }
  .social {
    position: absolute;
    right: 3rem;
    display: flex;
    justify-content: space-between;
    width: 8%;
    margin-top: 0.5rem;
    font-size: 2.5rem;
    a {
      color: globals.$color-2;
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    .social {
      width: 11%;
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    .legals {
      .legalsDetails {
        p {
          font-size: 1rem;
        }
      }
    }
    .social {
      right: 1.5rem;
      width: 14%;
      font-size: 2rem;
    }
  }
}

// MOBILE DEVICES //

@media screen and (max-width: 480px) {
  .container {
    .legals {
      .legalsDetails {
        p {
          font-size: 1rem;
        }
      }
    }
    .social {
      right: 1rem;
      flex-direction: column;
      justify-content: space-around;
      width: 4%;
      font-size: 1.3rem;
      a {
        margin-bottom: 0.5rem;
      }
    }
  }
}
