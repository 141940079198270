@use "../globals.module.scss" as globals;

@keyframes transition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  @include globals.flexColumnCenter;
  animation: transition 2s;
  margin: 0 auto;
  margin-top: 5rem;
  width: 70%;
  height: 350px;
  .pictureContainer {
    position: relative;
    height: 310px;
    .imgLink {
      @include globals.flexRowCenter;
      height: 100%;
      .img {
        height: 100%;
        max-height: 310px;
        border: 0.5px solid globals.$color-2;
        object-fit: contain;
        cursor: pointer;
      }
    }
    .legals {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 310px;
      background-color: rgba(0, 0, 0, 0.8);
      p {
        margin-bottom: 2rem;
        font-size: 1.1rem;
        font-weight: 300;
        color: globals.$color-2;
      }
      &:hover {
        opacity: 1;
        border: 0.5px solid globals.$color-2;
        transition: all 0.4s ease;
      }
    }
  }
  .arrowsAndCounter {
    display: flex;
    margin-top: 2rem;
    .leftArrow {
      @include globals.arrowConfig;
      margin-right: 2rem;
    }
    .counter {
      font-family: globals.$font-family-2;
      font-size: 1.4rem;
      font-weight: bold;
      
    }
    .rightArrow {
      @include globals.arrowConfig;
      margin-left: 2rem;
    }
  }
}

// SMALL SCREENS, LAPTOPS //

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    margin-top: 0;
    .pictureContainer {
      position: relative;
      height: 250px;
      .imgLink {
        .img {
          height: 100%;
          object-fit: cover;
        }
      }
      .legals {
        height: 100%;
        p {
          margin-bottom: 2rem;
          font-size: 1.1rem;
          font-weight: 300;
          color: globals.$color-2;
        }
        &:hover {
          opacity: 1;
          border: 0.5px solid globals.$color-2;
          transition: all 0.4s ease;
        }
      }
    }
    .arrowsAndCounter {
      display: flex;
      margin-top: 2rem;
    }
  }
}

// MOBILE DEVICES (CUSTOM) //

@media screen and (min-width: 380px) and (max-width: 480px) {
  .container {
    margin-top: 0;
    .pictureContainer {
      position: relative;
      height: 210px;
      .imgLink {
        height: 210px;
        .img {
          max-width: 350px;
          height: 100%;
          object-fit: cover;
        }
      }
      .legals {
        height: 210px;
        &:hover {
          opacity: 1;
          border: 0.5px solid globals.$color-2;
          transition: all 0.4s ease;
        }
      }
    }
  }
}

@media screen and (min-width: 290px) and (max-width: 379px) {
  .container {
    margin-top: 2rem;
    max-height: 400px;
    .pictureContainer {
      position: relative;
      height: fit-content;
      .imgLink {
        @include globals.flexRowCenter;
        height: auto;
        .img {
          max-width: 290px;
          height: auto;
        }
      }
      .legals {
        height: 100%;
        p {
          margin-bottom: 2rem;
          font-size: 1.1rem;
        }
        &:hover {
          border: none;
        }
      }
    }
  }
}
