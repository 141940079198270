@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: fit-content;
  margin: 0 2rem 0 2rem;
  .card {
    position: relative;
    display: flex;
    width: 400px;
    min-width: 270px;
    height: 300px;
    margin-bottom: 7rem;
    padding-left: 2rem;
    border-left: 1px solid globals.$color-1;
    .details {
      display: flex;
      align-items: center;
      img {
        z-index: 0;
        width: auto;
        max-width: 23rem;
        height: auto;
        max-height: 230px;
        border: 1px solid globals.$color-1;
        object-fit: cover;
      }
      .detailsText {
        margin-left: 1rem;
        h2 {
          margin-bottom: 1rem;
          font-family: globals.$font-family-2;
          font-size: 2.5rem;
        }
        p {
          margin-bottom: 1rem;
          font-size: 1.5rem;
          font-weight: 300;
        }
      }
    }
    .infos {
      z-index: 2;
      opacity: 0;
      @include globals.flexColumnCenter;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 2rem;
      span {
        font-style: italic;
      }
      .oldCreation {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: center;
      }
      .newCreation {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: justify;
      }
      .legals {
        margin: 1rem 0 0.5rem 0;
        font-size: 1.1rem;
        font-weight: 300;
      }
      a {
        margin-top: 2rem;
        padding: 1rem 2rem;
        font-size: 1.3rem;
        color: globals.$color-2;
        border: 1px solid globals.$color-2;
        transition: transform 300ms;
        &:hover {
          transform: scale(1.1);
        }
      }
      .buttons {
        margin-top: 2rem;
        a {
          border: none;
          cursor: pointer;
        }
        button {
          margin: 2rem 2rem 0 2rem;
          padding: 1rem 2rem;
          font-size: 1.3rem;
          color: globals.$color-2;
          background-color: globals.$color-1;
          border: 1px solid globals.$color-2;
          transition: transform 300ms;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &:hover {
        opacity: 0.9;
        color: globals.$color-2;
        background-color: globals.$color-1;
        cursor: pointer;
        transition: 0.5s;
      }
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    .card {
      .details {
        .detailsText {
          h2 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    .card {
      .details {
        .detailsText {
          h2 {
            font-size: 1.8rem;
          }
          p {
            margin-bottom: 1rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

// MOBILE DEVICES //

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
    .card {
      .details {
        img {
          z-index: 0;
          width: 13rem;
          height: 200px;
          border: 1px solid globals.$color-1;
        }
        .detailsText {
          h2 {
            font-size: 1.5rem;
          }
          p {
            font-size: 1.2rem;
          }
        }
      }
      .infos {
        padding: 1rem 1rem;
        .oldCreation {
          font-size: 1.1rem;
        }
        .newCreation {
          font-size: 1.1rem;
        }
        a {
          margin-top: 1.5rem;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
        }
        button {
          margin-top: 1.5rem;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
        }
      }
    }
  }
}
