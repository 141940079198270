@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  width: 100%;
  height: fit-content;
  margin-bottom: 7rem;
  padding: 0 5rem;
  h1 {
    @include globals.configh1;
    margin: 7rem 0 7rem 0;
  }
  .datesList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    height: 600px;
    margin: 19rem auto;
    .date {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5rem;
      p {
        font-size: 1.8rem;
        font-weight: 300;
        span {
          font-size: 1.8rem;
        }
        .monthAndYear {
          font-weight: bold;
        }
        .title {
          font-family: globals.$font-family-2;
          text-transform: uppercase;
        }
      }
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    h1 {
      margin: 5rem 0;
      font-size: 2.2rem;
    }
    .datesList {
      height: 550px;
      margin-bottom: 0;
      .date {
        p {
          font-size: 1.6rem;
          span {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

// IPADS, TABLETS // & // MOBILE DEVICES //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    h1 {
      margin: 5rem 0;
      font-size: 1.8rem;
    }
    .datesList {
      height: 500px;
      margin-bottom: 0;
      .date {
        p {
          font-size: 1.4rem;
          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 270px) and (max-width: 480px) {
  .container {
    h1 {
      margin: 5rem 0;
      font-size: 1.5rem;
    }
    .datesList {
        width: 80%;
        height: 550px;
      .date {
        p {
          font-size: 1.2rem;
          span {
            font-size: 1.2rem;
          }
          .monthAndYear {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 270px) and (max-width: 350px){
  .container {
    .datesList {
      height: 590px;
    }
  }
}
