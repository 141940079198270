@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .popupTitle {
    margin-bottom: 6rem;
  }
  .perfTitles {
    @include globals.flexRowCenter;
    flex-wrap: wrap;
    margin: 0 auto;
    span {
      font-family: globals.$font-family-2;
      font-size: 3rem;
    }
    p {
      position: relative;
      margin: 0.5rem 2rem;
      padding: 0.5rem 0;
      font-family: globals.$font-family-2;
      font-size: 1.5rem;
      cursor: pointer;
    }
    .shownPerf {
      color: globals.$color-2;
      &:after {
        @include globals.afterUnderline;
        height: 0.5px;
        background-color: globals.$color-2;
      }
      &:hover:after {
        left: 0;
        width: 100%;
      }
    }
    .notShownPerf {
      color: globals.$color-3;
      transition: all 0.3s;
      &:hover {
        color: globals.$color-2;
      }
      &:after {
        @include globals.afterUnderline;
        height: 0.5px;
        background-color: globals.$color-2;
      }
      &:hover:after {
        left: 0;
        width: 100%;
        color: globals.$color-2;
      }
    }
  }
}

// CUSTOM QUERIES //

@media screen and (max-width: 1239px) {
  .span {
    display: none;
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    .perfTitles {
      p {
        margin: 0 2rem;
        font-size: 1.4rem;
        text-align: center;
        word-break: break-word;
      }
    }
  }
}

// IPADS, TABLETS //

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    .perfTitles {
      flex-direction: column;
      flex-wrap: nowrap;
      p {
        margin: 0.5rem 0;
        font-size: 1.3rem;
        text-align: center;
        word-break: break-word;
      }
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 290px) and (max-width: 480px) {
  .container {
    .perfTitles {
      flex-direction: column;
      flex-wrap: nowrap;
      height: 150px;
      p {
        margin: 0.5rem 0;
        font-size: 1.2rem;
        text-align: center;
        word-break: break-word;
      }
      .shownPerf::after,
      .notShownPerf::after {
        height: 0;
      }
    }
  }
}
