@use "../globals.module.scss" as globals;

// DESKTOP //

.popupContainer {
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  padding: 25px;
  text-align: justify;
  word-break: break-word;
  color: globals.$color-2;
  background-color: globals.$color-1;
  overflow-y: scroll;
  .iconContainer {
    position: relative;
    float: right;
    z-index: 3;
    .icon {
      font-size: 2rem;
      cursor: pointer;
      transition: transform 300ms;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .contentAndCloseButton {
    position: relative;
    .closeButton {
      position: absolute;
      bottom: -6rem;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100px;
      padding: 10px 20px;
      font-size: 1.2rem;
      font-weight: 700;
      color: globals.$color-1;
      background-color: globals.$color-2;
      border: 2px solid globals.$color-1;
      cursor: pointer;
    }
  }
}

// MOBILES DEVICES //

@media screen and (max-width: 480px) {
  .popupContainer {
    .contentAndCloseButton {
      .closeButton {
        bottom: -3rem;
        font-size: 1.2rem;
      }
    }
  }
}
