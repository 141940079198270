@use "../globals.module.scss" as globals;

// DESKTOP //

.container {
  @include globals.flexColumnCenter;
  width: 100%;
  margin-bottom: 5rem;
  padding: 7rem 10rem;
  .aboutCompany {
    position: relative;
    padding: 0 10rem;
    h2 {
      @include globals.configh1;
      margin-bottom: 8rem;
    }
    p {
      margin: 0 auto;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      font-weight: 300;
      text-align: justify;
      &:last-child {
        float: right;
        margin-top: 2rem;
        font-family: globals.$font-family-2;
        text-transform: uppercase;
      }
      span {
        font-style: italic;
      }
    }
  }
  .aboutDancers {
    width: 90%;
    padding: 0 5rem;
    margin-top: 15rem;
    h2 {
      @include globals.configh1;
      margin-bottom: 8rem;
    }
    .sectionsContainer {
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      .larbiSection,
      .margotSection {
        position: relative;
        width: 40%;
        h3 {
          margin-bottom: 2rem;
          font-family: globals.$font-family-2;
          font-size: 2rem;
          font-weight: 300;
        }
        p {
          margin-bottom: 1.5rem;
          font-size: 1.5rem;
          font-weight: 300;
          text-align: justify;
        }
        .pictureContainer .img img {
          width: 15rem;
          height: 15rem;
          object-fit: cover;
        }
        .pictureContainer .legals {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          width: 15rem;
          height: 15rem;
          background-color: rgba(0, 0, 0, 0.6);
          p {
            font-size: 1.1rem;
            font-weight: 300;
            color: globals.$color-2;
          }
          &:hover {
            opacity: 1;
            transition: all 0.4s ease;
          }
        }
      }
      .larbiSection {
        .pictureContainer {
          position: absolute;
          top: 4.5rem;
          left: -17rem;
          width: 15rem;
          height: 15rem;
        }
      }
      .margotSection {
        .pictureContainer {
          position: absolute;
          top: 4.5rem;
          right: -14rem;
          width: 12rem;
          height: 12rem;
        }
      }
    }
  }
}

// SMALL SCREENS, LAPTOPS //

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    .aboutCompany h2,
    .aboutDancers h2 {
      font-size: 2.2rem;
    }
    .aboutCompany {
      padding: 0 5rem;
    }
    .aboutCompany p,
    .aboutDancers .sectionsContainer .larbiSection p,
    .aboutDancers .sectionsContainer .margotSection p {
      font-size: 1.4rem;
    }
    .aboutDancers {
      margin-top: 12rem;
      padding: 0 3rem;
      .sectionsContainer {
        width: 90%;
        .larbiSection,
        .margotSection {
          width: 40%;
        }
      }
    }
  }
}

// INTERMEDIATE QUERIES //

@media screen and (min-width: 450px) and (max-width: 650px) {
  .container {
    .aboutDancers {
      .sectionsContainer {
        margin-top: 18rem;
        padding: 0;
        .larbiSection,
        .margotSection {
          width: 100%;
          .pictureContainer {
            position: absolute;
            top: -12rem;
            left: 16rem;
          }
        }
        .margotSection {
          margin-top: 16rem;
        }
      }
    }
  }
}

@media screen and (min-width: 290px) and (max-width: 449px) {
  .container {
    .aboutDancers {
      .sectionsContainer {
        margin-top: 20rem;
        .larbiSection .pictureContainer,
        .margotSection .pictureContainer {
          position: absolute;
          top: -16rem;
          left: 0;
        }
        .margotSection {
          margin-top: 16rem;
        }
      }
    }
  }
}

// IPADS, TABLETS

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    padding: 3rem 3rem;
    .aboutCompany h2,
    .aboutDancers h2 {
      font-size: 1.8rem;
      letter-spacing: 1.8rem;
    }
    .aboutCompany p,
    .aboutDancers .sectionsContainer .larbiSection p,
    .aboutDancers .sectionsContainer .margotSection p {
      font-size: 1.2rem;
    }
    .aboutCompany {
      padding: 0 3rem;
    }
    .aboutDancers {
      margin-top: 12rem;
      padding: 0;
      .sectionsContainer {
        @include globals.flexColumnCenter;
        .larbiSection,
        .margotSection {
          width: 50%;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

// MOBILE DEVICES //

@media screen and (min-width: 290px) and (max-width: 480px) {
  .container {
    padding: 3rem 3rem;
    .aboutCompany h2,
    .aboutDancers h2 {
      font-size: 1.5rem;
      letter-spacing: 1.4rem;
      width: fit-content;
      margin: 0 auto;
    }
    .aboutCompany p,
    .aboutDancers .sectionsContainer .larbiSection p,
    .aboutDancers .sectionsContainer .margotSection p {
      font-size: 1.2rem;
    }
    .aboutCompany {
      padding: 0 3rem;
      h2 {
        margin-bottom: 3rem;
      }
    }
    .aboutDancers {
      margin-top: 8rem;
      padding: 0 1.5rem;
      .sectionsContainer {
        @include globals.flexColumnCenter;
        .larbiSection,
        .margotSection {
          width: 100%;
          margin-bottom: 2rem;
          h3 {
            font-size: 1.4rem;
          }
          .pictureContainer {
            img {
              width: 10rem;
            }
            p {
              width: fit-content;
              font-size: 1.1rem;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
